@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  height: 100%;
}

body {
  background-color: rgb(231, 169, 180);
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
}

h1, h2 {
  color: white;
}

li {
  text-decoration: none;
  list-style-type: none;
}

table {
  width: 100%;
  color: white;
  border-collapse: collapse;
  border-bottom: none;
  border-radius: 8px;
}

table, th, td, tr {
  border: none;
}

th, td {
  padding: 8px;
  text-align: center; /* Adjust alignment as needed */
  box-shadow: 0 1px 0px rgba(0, 0, 0, 0.1);
  border: none;
}

tbody tr:nth-child(even) {
  background-color: white;
  color: rgb(231, 169, 180);
}

a:visited {
  text-decoration: none;
  color: rgb(231, 169, 180);
}

a {
  text-decoration: none;
  color: rgb(231, 169, 180);  
}

.link-icon {
  text-decoration: none;
  color: white;
  margin-top: 4%;
  font-size: 24px;
}

input {
  border-radius: 8px;
  padding: .25%;
}

.main {
  height: screen;
  width: screen;
}

.table-title {
  margin-left: 1%;
}

.login-button {
  border: none;
  border-radius: 20px;
  background-color: white;
  color:rgb(231, 169, 180);
  width: 100%;
  height: 3em;
  cursor: pointer;
}

.nav {
  justify-content: left;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: left;
  height: 115%;
  background-color: rgba(255, 255, 255, 0.1); /* Adjust opacity as needed */
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.nav-row {
  width: 100%;
  margin-left: 1%;
  margin-right: 1%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.side-nav {
  position: fixed;
  width: 5%;
  height: 100%;
  border-right: lightslategray 1px solid;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.btn-group {
  width: 25%;
  display: flex;
  flex-direction: row;
  gap: 4%;
  margin-right: 2%;
}

.shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.submit-button {
  border: none;
  border-radius: 20px;
  background-color: white;
  color:rgb(231, 169, 180);
  width: 50%;
  height: 3em;
  cursor: pointer;
}

.title  {
  font-family: 'Sacramento', cursive;
  font-size: 3em;
  padding: 0;
  margin: 0;
  padding-top: 1%;
  color: white;
}

.tracker-body {
 position: absolute;
 left: 10%;
 top: 1% ;
 width: 80%;
 height: 98%;
 background-color: rgba(255, 255, 255, 0.1); /* Adjust opacity as needed */
 backdrop-filter: blur(10px);
 box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
 border-radius: 8px;
}

.registration-body {
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 35%;
  top: 25% ;
  width: auto;
  min-width: 30%;
  height: auto;
  background-color: rgba(255, 255, 255, 0.1); /* Adjust opacity as needed */
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  align-items: center;
  padding: 4%;
 }